<template>
  <div class="content error-page">
    <div v-if="error.statusCode === 404">
      <h1>
        {{ error.message }}
      </h1>
      <img src="/static_files/NotFound.svg" :alt="error.message">
      <p>
        Воспользуйтесь <nuxt-link to="/search">поиском</nuxt-link> по сайту или перейдите <nuxt-link to="/">на главную</nuxt-link> страницу.
      </p>
    </div>
    <div v-else-if="error.statusCode === 401">
      <h1>
        {{ error.message }}
      </h1>
      <div class="auth-social">
        <span @click="authSocial('google')" class="google"><i /> Google</span>
        <span @click="authSocial('mailru')" class="mailru"><i /> Mail.ru</span>
        <span @click="authSocial('yandex')" class="yandex"><i /> Яндекс</span>
      </div>
    </div>
    <div v-else>
      <h1>
        {{ error.message }}
      </h1>
    </div>
  </div>
</template>

<script>
export default {
  props: { error: { type: Object, default: () => ({}) } },
  head() {
    return {
      title: `Упс... Ошибка ${this.error.statusCode} - ${this.error.message}`,
      meta: [
        {
          hid: 'keywords',
          name: 'keywords',
          content: `Error 404`
        },
        {
          hid: 'description',
          name: 'description',
          content: `Сожалеем, но ${this.error.message}. Воспользуйтесь поиском по сайту или перейдите на главную страницу.`
        }
      ]
    }
  },
  methods: {
    authSocial(social) {
      location.href = `/api/auth/${social}`
    }
  }
}
</script>

<style lang="stylus">
.error-page
  text-align: center
  .auth-social
    grid-template-columns repeat(2, 1fr)
  a
    display: inline-block
    padding-bottom: 2px
    border-bottom: 1px solid rgba(255 255 255 .35)
    &:hover
      border-bottom: 1px solid #fff
  img
    max-width: 100%
    margin: 40px 0
</style>
