export const strict = false

export const state = () => ({
  user: null,
  error: '',
  feedback: false,
  siteLoad: false,
  isDesktop: false,
  tags: [],
})

export const mutations = {
  setDevice(state, payload) {
    state.isDesktop = payload.isDesktop
  },
  setUser(state, payload) {
    state.user = payload
  },
  setError(state, payload) {
    if (typeof payload === 'string') {
      payload = {
        status: 'info',
        msg: payload
      }
    }
    state.error = payload
  },
  setTags(state, payload) {
    state.tags = payload
  },
  clearError(state) {
    state.error = ''
  }
}

export const actions = {
  async nuxtServerInit({ commit }, { req, app, error }) {
    try {
      if (req.isAuthenticated()) commit('setUser', req.user)
      commit('setDevice', app.$device)

      const { data } = await app.$axios.get('/api/enterpoint')
      if (!data.error) {
        commit('setTags', data)
      }
    } catch (e) {
      error('Error on [nuxtServerInit] action', e)
    }
  }
}
