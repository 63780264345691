<template>
  <div v-cloak :class="`${!$isDesktop ? 'mobile' : '' }`">
    <nuxt />

    <transition name="toTop">
      <Error v-if="error" :error="error" />
    </transition>

    <Mobile v-if="!$isDesktop" />
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  components: {
    Error: () => import('~/components/Error.vue'),
    Mobile: () => import('~/components/Mobile.vue')
  },
  computed: {
    ...mapState(['isDesktop', 'error'])
  }
}
</script>
