import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _beeea55c = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _4153e13e = () => interopDefault(import('../pages/article/index.vue' /* webpackChunkName: "pages/article/index" */))
const _4bef2e7b = () => interopDefault(import('../pages/cp/index.vue' /* webpackChunkName: "pages/cp/index" */))
const _5586e788 = () => interopDefault(import('../pages/success.vue' /* webpackChunkName: "pages/success" */))
const _32c76154 = () => interopDefault(import('../pages/support.vue' /* webpackChunkName: "pages/support" */))
const _b28b953c = () => interopDefault(import('../pages/tag/index.vue' /* webpackChunkName: "pages/tag/index" */))
const _f3760898 = () => interopDefault(import('../pages/termsofuse.vue' /* webpackChunkName: "pages/termsofuse" */))
const _67944ca4 = () => interopDefault(import('../pages/cp/articles.vue' /* webpackChunkName: "pages/cp/articles" */))
const _a2feb64a = () => interopDefault(import('../pages/cp/comments.vue' /* webpackChunkName: "pages/cp/comments" */))
const _172b81ec = () => interopDefault(import('../pages/cp/feedback.vue' /* webpackChunkName: "pages/cp/feedback" */))
const _21c6e000 = () => interopDefault(import('../pages/cp/tags.vue' /* webpackChunkName: "pages/cp/tags" */))
const _3cbe2d29 = () => interopDefault(import('../pages/article/_id/index.vue' /* webpackChunkName: "pages/article/_id/index" */))
const _868baf6c = () => interopDefault(import('../pages/tag/_id.vue' /* webpackChunkName: "pages/tag/_id" */))
const _7f7eefd2 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _beeea55c,
    name: "about"
  }, {
    path: "/article",
    component: _4153e13e,
    name: "article"
  }, {
    path: "/cp",
    component: _4bef2e7b,
    name: "cp"
  }, {
    path: "/success",
    component: _5586e788,
    name: "success"
  }, {
    path: "/support",
    component: _32c76154,
    name: "support"
  }, {
    path: "/tag",
    component: _b28b953c,
    name: "tag"
  }, {
    path: "/termsofuse",
    component: _f3760898,
    name: "termsofuse"
  }, {
    path: "/cp/articles",
    component: _67944ca4,
    name: "cp-articles"
  }, {
    path: "/cp/comments",
    component: _a2feb64a,
    name: "cp-comments"
  }, {
    path: "/cp/feedback",
    component: _172b81ec,
    name: "cp-feedback"
  }, {
    path: "/cp/tags",
    component: _21c6e000,
    name: "cp-tags"
  }, {
    path: "/article/:id",
    component: _3cbe2d29,
    name: "article-id"
  }, {
    path: "/tag/:id",
    component: _868baf6c,
    name: "tag-id"
  }, {
    path: "/",
    component: _7f7eefd2,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
