<template>
  <div
    v-cloak
    :class="`${!isDesktop ? 'mobile' : '' }`"
    :style="`;background-color:#ddd;background-position: 0px 150px;background-repeat: no-repeat;`"
    class="site-body"
  >
  <!-- background-image:url(https://tpc.googlesyndication.com/simgad/18206483504484018875?) -->
    <Mobile v-if="!isDesktop" />

    <Menu />

    <div class="wrapper">
      <!-- <a href="https://ya.ru" class="brand-link" rel="nofollow noreferrer noopener">
        Levis
      </a> -->

      <Search />

      <nuxt />

      <div class="subscribe">
        <a href="/" class="logo">
          <b style="color:red;">L</b>ALEA
        </a>
        <strong>Подписка на рассылку</strong>
        <input type="email" v-model="subsEmail" placeholder="E-mail">
        <button @click="subscribe" class="dbtn red">Подписаться</button>
        <p>
          Подписываясь на рассылку lalea.ru вы принимаете условия <nuxt-link to="/termsofuse">пользовательского соглашения</nuxt-link>
        </p>
      </div>

      <div id="yandex_rtb_R-A-1970027-6" style="background-color:#fff;padding:30px 10px;"></div>

      <Footer />
    </div>

    <transition name="toTop">
      <Error v-if="error" :error="error" />
    </transition>

    <!-- <MovieAds v-if="showMovieAds" /> -->

    <!-- <div v-if="flyrollCounter" class="flyroll">
      <span class="close">
        {{ flyrollCounter }}
      </span>
      <div class="body">
        <Rsya :payload="{ blockid: 'R-A-1970027-5', page: 1 }" />
      </div>
    </div> -->
  </div>
</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'
import global from '~/mixins/global.js'
Vue.mixin(global)

export default {
  components: {
    // MovieAds: () => import('~/components/MovieAds.vue'),
    // Rsya: () => import('~/components/Rsya.vue'),
    Search: () => import('~/components/Search.vue'),
    Menu: () => import('~/components/Menu.vue'),
    Mobile: () => import('~/components/Mobile.vue'),
    Error: () => import('~/components/Error.vue'),
    Footer: () => import('~/components/Footer.vue')
  },
  watch: {
    // user() {
    //   this.setSiteBg()
    // },
    changeRoute() {
      this.checkShowVideoblock()
      if (!this.flyrollCounter) this.flyrollCounter = 40

      if (!this.app && !this.isDesktop && (!this.user || this.user.group === 1)) {
        if (this.showFS) return
        const rsyaMobileFS = document.createElement('script')
        rsyaMobileFS.innerHTML = `
window.yaContextCb.push(()=>{
  Ya.Context.AdvManager.render({
    type: 'fullscreen',
    blockId: 'R-A-1970027-4'
  })
})`
        document.body.appendChild(rsyaMobileFS)
        this.showFS = true
      }
    }
  },
  computed: {
    ...mapState(['error', 'isDesktop']),
    changeRoute() {
      return this.$route.fullPath
    }
  },
  data() {
    return {
      showFS: false,
      subsEmail: '',
      gets: {},
      adsBlock: {
        '2': false
      },
      showMovieAds: false,
      flyrollCounter: 40
    }
  },
  beforeMount() {
    this.$store.commit('setDevice', this.$device)
  },
  mounted() {
    this.showAd()

    window.addEventListener('scroll', this.lazyLoadImgs)
    window.addEventListener('scroll', this.showBlock)
    this.checkShowVideoblock()

    setInterval(() => {
      if (this.flyrollCounter) {
        --this.flyrollCounter
      } else {
        this.flyrollCounter = null
      }
    }, 1000)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.showBlock)
  },
  methods: {
    showAd() {
      const rsya1 = document.createElement('script')
      rsya1.innerHTML = `window.yaContextCb = window.yaContextCb || []`
      document.head.appendChild(rsya1)

      const rsya2 = document.createElement('script')
      rsya2.src = `https://yandex.ru/ads/system/context.js`
      rsya2.async = true
      document.head.appendChild(rsya2)

      setTimeout(() => {
        if (!this.isDesktop) {
          const rsyaFooterMobile = document.createElement('script')
          rsyaFooterMobile.innerHTML = `
window.yaContextCb.push(()=>{
  Ya.Context.AdvManager.render({
  type: 'floorAd',
  blockId: 'R-A-1970027-5'
  })
})`
          document.body.appendChild(rsyaFooterMobile)
        }
        const rsyaLenta = document.createElement('script')
        rsyaLenta.innerHTML = `
window.yaContextCb.push(()=>{
Ya.Context.AdvManager.renderFeed({
  "blockId": "R-A-1970027-6",
  "renderTo": "yandex_rtb_R-A-1970027-6"
})
})`
        document.body.appendChild(rsyaLenta)
      }, 200)
    },
    checkShowVideoblock() {
      this.showMovieAds = false
      setTimeout(() => {
        this.showMovieAds = true
      }, 1000)
    },
    showBlock() {
      const doc = document.documentElement.clientHeight
      const list = document.querySelectorAll('.banner.last')
      list.forEach(block => {
        const visible = doc - block.getBoundingClientRect().top
        const blockName = block.dataset.block
        if (visible > 0 && !this.adsBlock[blockName]) {
          this.adsBlock[blockName] = true
        }
      })
    },
    async subscribe() {
      this.$store.commit('clearError')
      if (!this.subsEmail.trim()) {
        this.$store.commit('setError', { status: 'info', msg: 'Укажите E-mail' })
        setTimeout(() => this.$store.commit('clearError'), 3000)
        return
      }
      if (this.gets.subscribe) return
      this.gets.subscribe = true
      const { data } = await this.$axios.post(`/api/subscribe`, { email: this.subsEmail.trim() })
      if (data.error) {
        this.$store.commit('setError', { status: 'warn', msg: data.error.message })
        setTimeout(() => this.$store.commit('clearError'), 3000)
      } else {
        this.$store.commit('setError', { status: 'ok', msg: data.message })
        setTimeout(() => this.$store.commit('clearError'), 3000)
      }
      this.subsEmail = ''
      delete this.gets.subscribe
    }
  },
}
</script>

<style src="~/assets/main.styl" lang="stylus" />
