const dev = !(process.env.NODE_ENV === 'production')
const baseURL = dev ? 'http://localhost:3008' : 'https://lalea.ru'
const SW_ID = Date.now()

module.exports = {
  server: {
    host: '127.0.0.1',
    port: 3008
  },
  dev,
  telemetry: false,
  router: {
    prefetchLinks: false
  },
  render: {
    bundleRenderer: {
      shouldPreload: (_file, type) => {
        return ['script', 'style', 'font'].includes(type)
      },
      pushAssets: (req, res, publicPath, preloadFiles) =>
      preloadFiles
        .filter(f => f.asType === 'script' && f.file === 'runtime.js')
        .map(f => `<${publicPath}${f.file}>; rel=preload; as=${f.asType}`)
    },
    static: {
      maxAge: 1000 * 60 * 60 * 24 * 365
    },
    // csp: true
  },

  /*
   ** Headers of the page
   */
  manifest: {
    name: 'LALEA',
    short_name: 'LALEA',
    display: 'standalone',
    lang: 'ru-RU',
    start_url: baseURL,
    background_color: '#fff',
    theme_color: '#fff',
    description: 'Ремонт, спорт, Ремонт, спорт, красота и здоровье от LALEA',
    api_version: 4,
    icons: [
      {
        src: `${baseURL}/static_files/android-icon-36x36.png`,
        sizes: '36x36',
        type: 'image/png',
        density: '0.75'
      },
      {
        src: `${baseURL}/static_files/android-icon-48x48.png`,
        sizes: '48x48',
        type: 'image/png',
        density: '1.0'
      },
      {
        src: `${baseURL}/static_files/android-icon-72x72.png`,
        sizes: '72x72',
        type: 'image/png',
        density: '1.5'
      },
      {
        src: `${baseURL}/static_files/android-icon-96x96.png`,
        sizes: '96x96',
        type: 'image/png',
        density: '2.0'
      },
      {
        src: `${baseURL}/static_files/android-icon-144x144.png`,
        sizes: '144x144',
        type: 'image/png',
        density: '3.0'
      },
      {
        src: `${baseURL}/static_files/android-icon-192x192.png`,
        sizes: '192x192',
        type: 'image/png',
        density: '4.0'
      },
      {
        src: `${baseURL}/static_files/apple-icon-512x512.png`,
        sizes: '512x512',
        type: 'image/png',
        density: '4.0'
      }
    ]
  },
  head: {
    htmlAttrs: {
      lang: 'ru',
      prefix:
        'og: http://ogp.me/ns# video: http://ogp.me/ns/video# ya: http://webmaster.yandex.ru/vocabularies/'
    },
    title: 'Ремонт, спорт, красота и здоровье - женский портал о красоте, эстетической медицине и здоровье',
    meta: [
      {
        hid: 'application-name',
        property: 'application-name',
        content: 'LALEA'
      },
      {
        charset: 'utf-8'
      },
      {
        name: 'viewport',
        content: 'width=device-width, initial-scale=1'
      },
      {
        name: 'theme-color',
        content: '#14161a'
      },
      {
        hid: 'og:type',
        property: 'og:type',
        content: 'website'
      },
      {
        hid: 'og:title',
        property: 'og:title',
        content: 'LALEA'
      },
      {
        hid: 'og:url',
        property: 'og:url',
        content: baseURL
      },
      {
        hid: 'description',
        name: 'description',
        content:
          'Популярный российский ресурс для мужчин и женщин о том, как сделать ремонт своими руками и сохранить молодость, красоту и здоровье.'
      },
      {
        hid: 'og:description',
        property: 'og:description',
        content:
          'Популярный российский ресурс для мужчин и женщин о том, как сделать ремонт своими руками и сохранить молодость, красоту и здоровье.'
      },
      {
        hid: 'keywords',
        name: 'keywords',
        content:
          'lalea, ремонт, спорт, тренировки, красота и здоровье, секреты женщин, дом и дача'
      },
      {
        hid: 'og:image',
        property: 'og:image',
        content: '/static_files/og_images/index.jpg'
      },
      {
        name: 'msapplication-TileColor',
        content: '#ffffff'
      },
      {
        name: 'msapplication-TileImage',
        content: '/static_files/ms-icon-144x144.png'
      }
    ],
    link: [
      {
        hid: 'opensearch',
        rel: 'search',
        type: 'application/opensearchdescription+xml',
        href: `${baseURL}/opensearch.xml`,
        title: 'Поиск по LALEA'
      },
      {
        hid: 'yandex-tableau-widget',
        rel: 'yandex-tableau-widget',
        href: `${baseURL}/tableau/tableau.json`
      },
      {
        hid: 'canonical',
        rel: 'canonical',
        href: baseURL
      },
      {
        hid: 'alternate',
        rel: 'alternate',
        href: baseURL
      },
      {
        rel: 'icon',
        type: 'image/x-icon',
        href: `${baseURL}/static_files/favicon.ico`
      },
      {
        rel: 'apple-touch-icon',
        sizes: '57x57',
        href: `${baseURL}/static_files/apple-icon-57x57.png`
      },
      {
        rel: 'apple-touch-icon',
        sizes: '60x60',
        href: `${baseURL}/static_files/apple-icon-60x60.png`
      },
      {
        rel: 'apple-touch-icon',
        sizes: '72x72',
        href: `${baseURL}/static_files/apple-icon-72x72.png`
      },
      {
        rel: 'apple-touch-icon',
        sizes: '76x76',
        href: `${baseURL}/static_files/apple-icon-76x76.png`
      },
      {
        rel: 'apple-touch-icon',
        sizes: '114x114',
        href: `${baseURL}/static_files/apple-icon-114x114.png`
      },
      {
        rel: 'apple-touch-icon',
        sizes: '120x120',
        href: `${baseURL}/static_files/apple-icon-120x120.png`
      },
      {
        rel: 'apple-touch-icon',
        sizes: '144x144',
        href: `${baseURL}/static_files/apple-icon-144x144.png`
      },
      {
        rel: 'apple-touch-icon',
        sizes: '152x152',
        href: `${baseURL}/static_files/apple-icon-152x152.png`
      },
      {
        rel: 'apple-touch-icon',
        sizes: '180x180',
        href: `${baseURL}/static_files/apple-icon-180x180.png`
      },
      {
        rel: 'icon',
        type: 'image/png',
        sizes: '192x192' ,
        href: `${baseURL}/android-icon-192x192.png`
      },
      {
        rel: 'icon',
        type: 'image/png',
        sizes: '512x512',
        href: `${baseURL}/static_files/apple-icon-512x512.png`
      },
      {
        rel: 'icon',
        type: 'image/png',
        sizes: '32x32',
        href: `${baseURL}/static_files/favicon-32x32.png`
      },
      {
        rel: 'icon',
        type: 'image/png',
        sizes: '96x96',
        href: `${baseURL}/static_files/favicon-96x96.png`
      },
      {
        rel: 'icon',
        type: 'image/png',
        sizes: '16x16',
        href: `${baseURL}/static_files/favicon-16x16.png`
      }
    ],
//     script: [
//       {
//         hid: 'rsya-inner',
//         innerHTML: `window.yaContextCb=window.yaContextCb||[]`
//       },
//       {
//         hid: 'rsya-src',
//         src: `https://yandex.ru/ads/system/context.js`,
//         async: true
//       },
// //       {
// //         src: 'https://www.googletagmanager.com/gtag/js?id=G-PMYVLM20DS',
// //         async: true
// //       },
// //       {
// //         innerHTML: `
// // window.dataLayer = window.dataLayer || [];
// // function gtag(){dataLayer.push(arguments);}
// // gtag('js', new Date());

// // gtag('config', 'G-PMYVLM20DS');`
// //       },
//       // { rel: 'preconnect', href: 'https//www.googletagmanager.com', crossorigin: 'crossorigin' }
//     ],
//     __dangerouslyDisableSanitizers: ['script']
  },

  /*
  ** Customize the progress-bar color
  */
  loading: {
    color: '#ffffff', // '#e00a1e',
    height: '2px'
  },

  /*
  ** Global CSS
  */
  css: [],

  /*
  ** Plugins to load before mounting the App
  */
  plugins: [
    '~/plugins/lazysizes.js'
  ],

  // serverMiddleware: ['~/servermiddleware/seo.js'],

  /*
  ** Nuxt.js modules
  */
  modules: [
    // Doc: https://axios.nuxtjs.org/usage
    'nuxt-ssr-cache',
    'nuxt-helmet',
    '@nuxtjs/device',
    '@nuxtjs/axios',
    [
      '@naumstory/nuxtjs-yandex-metrika',
      {
        id: '87686068',
        webvisor:true,
        // clickmap:false,
        // useCDN:true,
        // trackLinks:false,
        // accurateTrackBounce:false
      }
    ],
    // [
    //   '@nuxtjs/google-adsense',
    //   {
    //     id: 'ca-pub-7342579429621599',
    //     // onPageLoad: true,
    //     pageLevelAds: true
    //   }
    // ]
  ],
  cache: {
    useHostPrefix: true,
    pages: [
      /^\/$/,
      /^\/article/,
      /^\/article\/.*/,
      /^\/tag\/.*/,
    ],
    key(route, context) {
      // console.log(context.req.isAuthenticated())
      if (dev) return
      
      const pages = [
        /^\/$/,
        /^\/article/,
        /^\/article\/.*/,
        /^\/tag\/.*/,
      ]
      const url = baseURL.slice(baseURL.indexOf('//') + 2) + route

      const isMobile = context.req.headers['user-agent'].match(/(iPhone|iPod|iPad|Android|BlackBerry)/)
      if (dev) console.log('isMobile', isMobile)

      if (context.req.isAuthenticated()) {
        return false
      } else if (pages.some(el => route.match(el))) {
        return url + (isMobile ? `_mobile` : '')
      }
    },
    store: {
      type: 'redis',
      host: 'localhost',
      ttl: 12 * 60 * 60,
      configure: [
        ['maxmemory', '200mb'],
        ['maxmemory-policy', 'allkeys-lru'],
      ],
    },
  },
  /*
  ** Axios module configuration
  */
  axios: {
    // See https://github.com/nuxt-community/axios-module#options
    baseURL
  },

  env: {
    SW_ID
  },

  buildModules: [
    [
      '@nuxtjs/google-fonts',
      {
        families: {
          'Montserrat': [400, 500, 700]
        },
        display: 'swap',
      }
    ],
    [
      "nuxt-compress",
      {
        gzip: {
          cache: true
        },
        brotli: {
          threshold: 10240
        }
      }
    ],
    // '@nuxtjs/pwa'
  ],

  pwa: {
    workbox: {
      runtimeCaching: [
        {
          urlPattern: `${baseURL}/.*`,
          strategyOptions: {
            cacheName: 'our-cache',
          },
          strategyPlugins: [{
             use: 'Expiration',
             config: {
               maxEntries: 10,
               maxAgeSeconds: 60
             }
           }]
        }
      ]
    }
  },

  /*
  ** Build configuration
  */
  build: {
    /*
    ** You can extend webpack config here
    */
    publicPath: '/dist/client/',
    // extractCSS: true,
    filenames: {
      app: ({ isDev, isModern }) => isDev ? `[name]${isModern ? '.modern' : ''}.js` : `[contenthash:7]${isModern ? '.modern' : ''}.js`,
      chunk: ({ isDev, isModern }) => isDev ? `[name]${isModern ? '.modern' : ''}.js` : `[contenthash:7]${isModern ? '.modern' : ''}.js`,
      css: ({ isDev }) => isDev ? '[name].css' : 'css/[contenthash:7].css',
      img: ({ isDev }) => isDev ? '[path][name].[ext]' : 'img/[name].[contenthash:7].[ext]',
    },
    extend(config, ctx) {
      // Run ESLint on save
      if (ctx.isDev && ctx.isClient) {
        config.module.rules.push({
          enforce: 'pre',
          test: /\.(js|vue)$/,
          loader: 'eslint-loader',
          exclude: /(node_modules)/
        })
      }

      if (ctx.isClient) {
        config.devtool = 'source-map'
      }
    }
  }
}
